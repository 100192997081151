export default function Pasien() {
    return {
        id: null,
        addresses: [],
        agama: null,
        cityOfBirth: null,
        dateOfBirth: null,
        email: null,
        etnis: null,
        firstName: null,
        golDarah: null,
        jenisKelamin: null,
        kodeKonfirmasi: null,
        lastName: null,
        medicalNo: null,
        mobilePhone1: null,
        mobilePhone2: null,
        occupation: null,
        oldMedicalNo: null,
        patientName: null,
        patientNames: null,
        patientNameOnCard: null,
        pendidikan: null,
        preferredName: null,
        ssn: null,
        statusPernikahan: null,
        suffix: null,
        title: null,
        userId: null,
        wargaNegara: null
    };
}
